const data = [
  [
    {
      question_text: "1_01_012.jpg",
      chapter: 1,
      correctanswer: 4,
      answernumber: 4,
    },
    {
      question_text: "1_01_018.jpg",
      chapter: 1,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_01_019.jpg",
      chapter: 1,
      correctanswer: 1,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_02_002.jpg",
      chapter: 2,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_02_004.jpg",
      chapter: 2,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_02_006.jpg",
      chapter: 2,
      correctanswer: 3,
      answernumber: 4,
    },
  ],
  [
    {
      question_text: "1_03_003.jpg",
      chapter: 3,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_03_005.jpg",
      chapter: 3,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_03_012.jpg",
      chapter: 3,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_03_020.jpg",
      chapter: 3,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_03_021.jpg",
      chapter: 3,
      correctanswer: 3,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_04_006.jpg",
      chapter: 4,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_04_010.jpg",
      chapter: 4,
      correctanswer: 1,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_05_011.jpg",
      chapter: 5,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_05_012.jpg",
      chapter: 5,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_05_029.jpg",
      chapter: 5,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_05_037.jpg",
      chapter: 5,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_05_053.jpg",
      chapter: 5,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_05_075.jpg",
      chapter: 5,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_05_090.jpg",
      chapter: 5,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_05_093.jpg",
      chapter: 5,
      correctanswer: 2,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_06_002.jpg",
      chapter: 6,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_06_011.jpg",
      chapter: 6,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_06_026.jpg",
      chapter: 6,
      correctanswer: 2,
      answernumber: 2,
    },
  ],
  [
    {
      question_text: "1_07_015.jpg",
      chapter: 7,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_07_027.jpg",
      chapter: 7,
      correctanswer: 1,
      answernumber: 4,
    },
    {
      question_text: "1_07_031.jpg",
      chapter: 7,
      correctanswer: 4,
      answernumber: 4,
    },
    {
      question_text: "1_07_032.jpg",
      chapter: 7,
      correctanswer: 1,
      answernumber: 4,
    },
  ],
  [
    {
      question_text: "1_08_008.jpg",
      chapter: 8,
      correctanswer: 3,
      answernumber: 4,
    },
    {
      question_text: "1_08_010.jpg",
      chapter: 8,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_08_017.jpg",
      chapter: 8,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_08_032.jpg",
      chapter: 8,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_08_034.jpg",
      chapter: 8,
      correctanswer: 3,
      answernumber: 4,
    },
  ],
  [
    {
      question_text: "1_09_004.jpg",
      chapter: 9,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_09_009.jpg",
      chapter: 9,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_09_018.jpg",
      chapter: 9,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_09_025.jpg",
      chapter: 9,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_09_035.jpg",
      chapter: 9,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_09_040.jpg",
      chapter: 9,
      correctanswer: 1,
      answernumber: 5,
    },
    {
      question_text: "1_09_047.jpg",
      chapter: 9,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_09_051.jpg",
      chapter: 9,
      correctanswer: 4,
      answernumber: 5,
    },
    {
      question_text: "1_09_070.jpg",
      chapter: 9,
      correctanswer: 2,
      answernumber: 2,
    },
  ],
  [
    {
      question_text: "1_10_011.jpg",
      chapter: 10,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_10_015.jpg",
      chapter: 10,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_10_030.jpg",
      chapter: 10,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_10_033.jpg",
      chapter: 10,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_10_039.jpg",
      chapter: 10,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_10_044.jpg",
      chapter: 10,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_10_056.jpg",
      chapter: 10,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_10_063.jpg",
      chapter: 10,
      correctanswer: 2,
      answernumber: 2,
    },
  ],
  [
    {
      question_text: "1_11_005.jpg",
      chapter: 11,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_11_008.jpg",
      chapter: 11,
      correctanswer: 4,
      answernumber: 4,
    },
    {
      question_text: "1_11_011.jpg",
      chapter: 11,
      correctanswer: 4,
      answernumber: 4,
    },
    {
      question_text: "1_11_015.jpg",
      chapter: 11,
      correctanswer: 1,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_12_007.jpg",
      chapter: 12,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_12_010.jpg",
      chapter: 12,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_12_015.jpg",
      chapter: 12,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_12_017.jpg",
      chapter: 12,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_12_020.jpg",
      chapter: 12,
      correctanswer: 2,
      answernumber: 2,
    },
  ],
  [
    {
      question_text: "1_13_018.jpg",
      chapter: 13,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_13_028.jpg",
      chapter: 13,
      correctanswer: 2,
      answernumber: 4,
    },
    {
      question_text: "1_13_037.jpg",
      chapter: 13,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_13_039.jpg",
      chapter: 13,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_13_042.jpg",
      chapter: 13,
      correctanswer: 1,
      answernumber: 4,
    },
    {
      question_text: "1_13_045.jpg",
      chapter: 13,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_13_052.jpg",
      chapter: 13,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_13_072.jpg",
      chapter: 13,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_13_077.jpg",
      chapter: 13,
      correctanswer: 2,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_14_006.jpg",
      chapter: 14,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_14_008.jpg",
      chapter: 14,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_14_011.jpg",
      chapter: 14,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_14_014.jpg",
      chapter: 14,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_14_017.jpg",
      chapter: 14,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_14_023.jpg",
      chapter: 14,
      correctanswer: 3,
      answernumber: 4,
    },
    {
      question_text: "1_14_026.jpg",
      chapter: 14,
      correctanswer: 1,
      answernumber: 2,
    },
    {
      question_text: "1_14_031.jpg",
      chapter: 14,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_14_036.jpg",
      chapter: 14,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_14_045.jpg",
      chapter: 14,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_14_046.jpg",
      chapter: 14,
      correctanswer: 2,
      answernumber: 4,
    },
    {
      question_text: "1_14_057.jpg",
      chapter: 14,
      correctanswer: 1,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_15_005.jpg",
      chapter: 15,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_15_010.jpg",
      chapter: 15,
      correctanswer: 1,
      answernumber: 2,
    },
  ],
  [
    {
      question_text: "1_16_009.jpg",
      chapter: 16,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_16_015.jpg",
      chapter: 16,
      correctanswer: 1,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_17_003.jpg",
      chapter: 17,
      correctanswer: 3,
      answernumber: 4,
    },
    {
      question_text: "1_17_005.jpg",
      chapter: 17,
      correctanswer: 2,
      answernumber: 3,
    },
    {
      question_text: "1_17_008.jpg",
      chapter: 17,
      correctanswer: 3,
      answernumber: 4,
    },
  ],
  [
    {
      question_text: "1_18_004.jpg",
      chapter: 18,
      correctanswer: 3,
      answernumber: 3,
    },
    {
      question_text: "1_19_009.jpg",
      chapter: 18,
      correctanswer: 3,
      answernumber: 4,
    },
    {
      question_text: "1_20_007.jpg",
      chapter: 18,
      correctanswer: 1,
      answernumber: 4,
    },
  ],
  [
    {
      question_text: "1_21_005.jpg",
      chapter: 19,
      correctanswer: 2,
      answernumber: 4,
    },
    {
      question_text: "1_21_009.jpg",
      chapter: 19,
      correctanswer: 4,
      answernumber: 4,
    },
    {
      question_text: "1_21_012.jpg",
      chapter: 19,
      correctanswer: 2,
      answernumber: 2,
    },
    {
      question_text: "1_21_013.jpg",
      chapter: 19,
      correctanswer: 1,
      answernumber: 3,
    },
    {
      question_text: "1_21_017.jpg",
      chapter: 19,
      correctanswer: 4,
      answernumber: 4,
    },
    {
      question_text: "1_21_020.jpg",
      chapter: 19,
      correctanswer: 2,
      answernumber: 3,
    },
  ],
  [
    {
      question_text: "1_22_008.jpg",
      chapter: 20,
      correctanswer: 3,
      answernumber: 4,
    },
    {
      question_text: "1_22_009.jpg",
      chapter: 20,
      correctanswer: 1,
      answernumber: 4,
    },
    {
      question_text: "1_22_015.jpg",
      chapter: 20,
      correctanswer: 4,
      answernumber: 4,
    },
    {
      question_text: "1_22_019.jpg",
      chapter: 20,
      correctanswer: 5,
      answernumber: 5,
    },
  ],
];
export default data;
