import { useEffect, useState } from "react";
import data from "./data";

const AnswerList = ({ answernumber, data, handleAnswer }) => {
  const elements = [];

  for (let i = 0; i < answernumber; i++) {
    elements.push(
      <div
        style={{
          padding: "2px 0px",
          flex: "1 1 20px",
          textAlign: "center",
          backgroundColor: `${
            data.answered
              ? data.answered !== i + 1
                ? "#e3e3e3"
                : data.answered === data.correctanswer
                ? "#00bfa5"
                : "#ec407a"
              : "#e3e3e3"
          }`,
          border: "1px solid #dfdfdf",
          borderRadius: `${
            i === 0
              ? "0px 0px 0px 5px"
              : i + 1 === answernumber
              ? "0px 0px 5px 0px"
              : "0px 0px 0px 0px"
          }`,
          color: `${
            data.answered === i + 1 && data.answered ? "#fff" : "#353935"
          }`,
          // margin: "5px",
          margin: `${
            i === 0
              ? "0px 5px 0px 0px"
              : i + 1 === answernumber
              ? "0px 0px 0px 5px"
              : "0px 5px 0px 5px"
          }`,
          fontWeight: "bold",
        }}
        key={i}
        onClick={() => {
          if (!data.answered) {
            handleAnswer(data, i + 1);
          }
        }}
      >
        {i + 1}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "0px",
      }}
    >
      {elements}
    </div>
  );
};
const Modal = ({ isOpen, onClose, handleRefresh, message }) => {
  if (!isOpen) return null; // Don't render anything if not open

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h3>ШАЛГАЛТЫН ХАРИУ</h3>
        <p>{message}</p>
        <button onClick={handleRefresh} style={styles.refreshButton}>
          Эхлэх
        </button>
        <button onClick={onClose} style={styles.closeButton}>
          Хаах
        </button>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  closeButton: {
    marginTop: "15px",
    padding: "10px 20px",
    border: "none",
    backgroundColor: "#42a5f5",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
  },
  refreshButton: {
    marginTop: "15px",
    marginRight: "15px",
    padding: "10px 20px",
    border: "none",
    backgroundColor: "#42a5f5",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
  },
};
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleRefresh = () => {
    window.location.reload();
  };
  const showNotification = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [lang, setLang] = useState(false);
  const [total, setTotal] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [menu, setMenu] = useState([]);
  useEffect(() => {
    const menuData = data.map((dt) => {
      const number = Math.floor(Math.random() * 100) % dt.length;
      return { ...dt[number], answered: false };
    });
    setMenu(menuData);
  }, []);
  const handleAnswer = (data, answer) => {
    const menuData = menu.map((dt) => {
      if (dt.question_text === data.question_text) {
        return { ...dt, answered: answer };
      } else {
        return dt;
      }
    });
    setMenu(menuData);
  };
  useEffect(() => {
    let correct = 0,
      total = 0;
    menu.map((dt) => {
      if (dt.answered === dt.correctanswer) {
        correct += 1;
      }
      if (dt.answered) {
        total += 1;
      }
      return dt;
    });
    setCorrect(correct);
    setTotal(total);
    if (total === 20) {
      showNotification();
    }
  }, [menu]);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          fontFamily: "Roboto",
          maxWidth: "600px",
        }}
      >
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          handleRefresh={handleRefresh}
          message={`${
            correct > 17
              ? `Та 20-н асуултнаас ${correct} зөв хариулсан байна.`
              : `Та 20-н асуултнаас ${20 - correct} буруу хариулсан байна.`
          }`}
        />
        <div
          style={{
            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
            marginBottom: "10px",
            borderRadius: "5px",
            position: "sticky",
            top: "0px",
            zIndex: "1000",
            backgroundColor: "#f6f7f6",
            display: "flex",
            border: "1px solid #eee",
          }}
        >
          <div
            style={{
              backgroundColor: "#42a5f5",
              borderRadius: "5px 0px 0px 5px",
              color: "white",
              padding: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {total}/20
          </div>
          <div style={{ padding: "10px" }}>
            {menu.map((dt, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    background: `${
                      !dt.answered
                        ? "#dfdfdf"
                        : dt.correctanswer === dt.answered
                        ? "#00bfa5"
                        : "#ec407a"
                    }`,
                    padding: "2px",
                    margin: "2px",
                    borderRadius: "5px",
                    alignItems: "center",
                    textAlign: "center",
                    color: `${!dt.answered ? "#353935" : "#fff"}`,
                  }}
                >
                  {dt.chapter}
                </div>
              );
            })}
          </div>
          <div
            style={{
              backgroundColor: "#eee",
              borderRadius: "0px 5px 5px 0px",
              color: "white",
              padding: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {lang ? (
              <div
                onClick={() => {
                  setLang(() => false);
                }}
              >
                <img alt="хэл сонгох" src="/flag/mongolia.png" width="25px" />
              </div>
            ) : (
              <div
                onClick={() => {
                  setLang(() => true);
                }}
              >
                <img alt="хэл сонгох" src="/flag/usa.png" width="25px" />
              </div>
            )} */}
          </div>
        </div>
        <div
          style={{
            width: "97%",
            marginLeft: "5px",
          }}
        >
          {menu.map((dt, idx) => {
            return (
              <div
                key={idx}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  boxShadow: "3px 5px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "5px",
                  paddingTop: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#42a5f5",
                    borderRadius: "25px",
                    color: "white",
                    width: "20px",
                    height: "20px",
                    padding: "5px",
                    display: "flex",
                    margin: "0px 0px 0px 10px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {idx + 1}
                </div>
                <div style={{ padding: "5px" }}>
                  <img
                    alt="asuult1"
                    width="100%"
                    src={`/questions/${dt.question_text}`}
                  />
                </div>
                <div
                  style={{
                    padding: "5px",
                    borderTop: "1px solid #dfdfdf",
                    fontSize: "0.9rem",
                  }}
                >
                  Хариулт сонгох:
                </div>
                <AnswerList
                  answernumber={dt.answernumber}
                  data={dt}
                  handleAnswer={handleAnswer}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
